import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const HistoryLogger = () => {
  const location = useLocation();

  useEffect(() => {
    let history = JSON.parse(localStorage.getItem("history")) || [];
    
    history = [...history.slice(-1), location.pathname]; 
    
    localStorage.setItem("history", JSON.stringify(history));
  }, [location]);


  return null;
};

export default HistoryLogger;
