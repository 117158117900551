import React from 'react';
import { Route, useHistory } from 'react-router-dom';

const HomeRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.getItem('token') ? (
          <Component {...props} />
        ) : (
          history.push('/statistics')
        )
      }
    />
  );
};

export default HomeRoute;
