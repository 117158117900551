import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as IconComment } from '../../img/sprite/svg/comment.svg';

const Comments = ({
  showAdditionalAnswersGraph,
  quizId,
  questionId,
  additionalQuestionsData,
  button
}) => {
  const { t } = useTranslation();
  
  const onClickHandler = () => {
    const pollData = additionalQuestionsData.current?.polls?.filter((item) =>
      item.settings?.conditions.includes(button)
    );
    showAdditionalAnswersGraph(quizId, questionId, pollData[0], button);
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{t('statisticPage.comments')}</Tooltip>}
    >
      <p className="rate__responses">
        <button
          className="btn-responses"
          type="button"
          onClick={() => onClickHandler()}
        >
          <IconComment className="svg-sprite-icon icon-comment" />
        </button>
      </p>
    </OverlayTrigger>
  );
};

export default Comments;

Comments.propTypes = {
  showAdditionalAnswersGraph: PropTypes.func,
  quizId: PropTypes.number,
  questionId: PropTypes.number,
  additionalQuestionsData: PropTypes.shape({}),
  button: PropTypes.string
};

Comments.defaultProps = {
  showAdditionalAnswersGraph: null,
  quizId: null,
  questionId: null,
  additionalQuestionsData: null,
  button: ''
};
