import React, { useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './scss/style.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalContext from './context/globalContext';
import globalReducer, { initialState } from './reducers/globalReducer';
import {
  AccountPage,
  UsersPage,
  DevicesPage,
  LoginPage,
  PollsPage,
  RatingPage,
  ReportsPage,
  ResetPasswordPage,
  StatisticPage,
  NotFoundPage,
  ServerErrorPage,
  QrVotePage,
  HelpPage,
  ReviewsPage,
  BackStagePage
} from './Layouts';
import FirstPage from './Layouts/FirstPage';
import DeviceEdit from './components/Devices/DeviceEdit';
import UserEdit from './components/Users/UserEdit';
import PollEdit from './components/Polls/PollEdit';
import NewOrEditReports from './components/Reports/NewOrEditReport';
import HelpModal from './components/Modals/HelpModal';
import PrivacyTerms from './Layouts/PrivacyTerms';
import PrivateRoute from './routing/PrivateRoute';
import HomeRoute from './routing/HomeRoute';
import HistoryLogger from './routing/HistoryLogger';

const App = () => {
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const isOwnerAdmin = state.user?.is_owner || state.user?.is_admin;
  const userHasHardware = state.user?.firm?.has_hardware;
  return (
    <GlobalContext.Provider
      value={{ dispatchGlobal: dispatch, stateGlobal: state }}
    >
      <ToastContainer />
      <Router>
        <HistoryLogger />
        <Switch>
          {/* Public routes (No token needed) */}
          <Route path="/login" exact component={LoginPage} />
          <Route
            path="/resetPassword/:resetToken"
            exact
            component={ResetPasswordPage}
          />
          <Route path="/device/vote/:uuid" exact component={QrVotePage} />
          <Route path="/policy" exact component={PrivacyTerms} />
          <Route path="/terms" exact component={PrivacyTerms} />
          <Route path="/backstage" exact component={BackStagePage} />
          <Route path="/errorPage" exact component={ServerErrorPage} />
          <HomeRoute path="/" exact component={FirstPage} />
          {/* Protected routes (Require token) */}
          <PrivateRoute path="/account" exact component={AccountPage} />
          <PrivateRoute path="/rating" exact component={RatingPage} />
          <PrivateRoute path="/polls" exact component={PollsPage} />
          <PrivateRoute path="/reports" exact component={ReportsPage} />
          <PrivateRoute path="/reviews" exact component={ReviewsPage} />
          <PrivateRoute path="/statistics" exact component={StatisticPage} />
          {/* Admin-only routes */}
          {isOwnerAdmin && (
            <PrivateRoute path="/users" exact component={UsersPage} />
          )}
          {isOwnerAdmin && (
            <PrivateRoute path="/users/addUser" exact component={UserEdit} />
          )}
          {isOwnerAdmin && (
            <PrivateRoute path="/users/:userId" exact component={UserEdit} />
          )}
          {/* <Route path="/faq" exact component={HelpPage} /> */}
          {isOwnerAdmin && (
            <PrivateRoute path="/polls/pollCreate" exact component={PollEdit} />
          )}
          {isOwnerAdmin && (
            <PrivateRoute path="/polls/:pollId" exact component={PollEdit} />
          )}
          {userHasHardware && (
            <PrivateRoute path="/devices" exact component={DevicesPage} />
          )}
          {isOwnerAdmin && userHasHardware && (
            <PrivateRoute
              path="/device/:singleDeviceId"
              exact
              component={DeviceEdit}
            />
          )}
          <PrivateRoute
            path="/reports/create"
            exact
            component={NewOrEditReports}
          />
          <PrivateRoute
            path="/reports/:reportId"
            exact
            component={NewOrEditReports}
          />
          {/* Catch-all for 404 */}
          <Route component={NotFoundPage} />
        </Switch>
        <HelpModal show={state.helpDeskModal} />
      </Router>
    </GlobalContext.Provider>
  );
};

export default App;
