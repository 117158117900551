import React, { useState, useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Carousel } from 'react-bootstrap';
import percentRound from 'percent-round';
import ServiceAPI from '../../services';
import useDates from '../../hooks/useDates';
import AdditionalAnswerModalCarouselItem from './AdditionalAnswerModalCarouselItem';

const commentsPerPage = 5;

const AdditionalAnswersModal = ({ show, onHide, data }) => {
  const service = new ServiceAPI();

  const [commentsPoll, setCommentsPoll] = useState([]);
  const [commentsAmount, setCommentsAmount] = useState([0]);
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [triggerForChangingPage, setTriggerForChangingPage] = useState([]);

  const [answers, setAnswers] = useState([]);
  const [answersTitles, setAnswersTitles] = useState([]);
  const [index, setIndex] = useState(0);
  const [answersLoading, setAnswersLoading] = useState(false);
  const [percents, setPercents] = useState(0);

  const [createTimeOrdering, setCreateTimeOrdering] = useState(true);

  const [count, setCount] = useState([]);

  const { dates } = useDates();
  const dateTo = moment(dates?.to).utc().format().replace('Z', '');
  const dateFrom = moment(dates?.from).utc().format().replace('Z', '');

  const dataForBack = {
    quizId: data?.quizId,
    questionId: data?.questionId,
    pollId: data?.pollData?.id
  };

  const getOrdering = () => {
    return createTimeOrdering ? '-created_at' : 'created_at';
  };

  useEffect(() => {
    if (show) {
      setAnswersLoading(true);
      const allComments = [];
      service
        .qrVoteQuestionAnswers({
          ...dataForBack,
          data: {
            conditions: data?.button,
            created_at_before: dateTo,
            created_at_after: dateFrom
          }
        })
        .then((resp) => {
          if (resp.status === 200) {
            const sortedRespByPage = sortBy(resp.data.results, ['page']);
            const titles = [];
            const backAnswers = sortedRespByPage.map((item, i) => {
              allComments[i] = item.poll_questions.find(
                (question) => question.type === 'text'
              );
              titles.push(item.title);
              return item.poll_questions.filter((item) => item.type === 'bool');
            });
            setAnswersTitles(titles);
            setTriggerForChangingPage(Array(titles.length).fill(0));
            setAnswers(backAnswers);
            setCommentsPoll(allComments);
            setAnswersLoading(false);
          }
        })
        .then(() => {
          if (allComments.length) {
            setCommentsLoading(true);
            service
              .qrVoteQuestionComments({
                ...dataForBack,
                commentsId: allComments[0]?.id,
                data: {
                  ordering: getOrdering(),
                  created_at_before: dateTo,
                  created_at_after: dateFrom,
                  limit: commentsPerPage
                }
              })
              .then((resp) => {
                if (resp.status === 200) {
                  const newCount = [...count];
                  newCount[index] = resp.data.count;
                  setCount(newCount);
                  const newCommentsAmount = [...commentsAmount];
                  newCommentsAmount[index] = resp.data.count;
                  setCommentsAmount(newCommentsAmount);
                  const newComments = [...comments];
                  newComments[index] = resp.data.results;
                  setComments(newComments);
                  setCommentsLoading(false);
                }
              });
          }
        });
    } else {
      setAnswersTitles([]);
      setAnswers([]);
      setAnswersLoading(false);
      setCommentsAmount([0]);
      setComments([]);
      setCommentsLoading(false);
      setCount([]);
      setCreateTimeOrdering(true);
      setTriggerForChangingPage([]);
      setIndex(0);
    }
  }, [show]);

  useEffect(() => {
    if (answers.length && !comments[index] && commentsPoll[index]) {
      setCommentsLoading(true);
      service
        .qrVoteQuestionComments({
          ...dataForBack,
          commentsId: commentsPoll[index]?.id,
          data: {
            ordering: getOrdering(),
            created_at_before: dateTo,
            created_at_after: dateFrom,
            limit: commentsPerPage
          }
        })
        .then((resp) => {
          if (resp.status === 200) {
            const newCount = [...count];
            newCount[index] = resp.data.count;
            setCount(newCount);
            const newCommentsAmount = [...commentsAmount];
            newCommentsAmount[index] = resp.data.count;
            setCommentsAmount(newCommentsAmount);
            const newComments = [...comments];
            newComments[index] = resp.data.results;
            setComments(newComments);
            const newTrigger = [...triggerForChangingPage];
            newTrigger[index] = newTrigger[index] + 1;
            setTriggerForChangingPage(newTrigger);
            setCommentsLoading(false);
          }
        });
    }
  }, [index]);

  useEffect(() => {
    if (answers.length > 0) {
      const percents = [];
      answers.forEach((item, i) => {
        let total = 0;
        const allAnswers = sortBy(item, ['position']).map((item) => {
          total += item.count_positive_answers;
          return item.count_positive_answers;
        });
        const allPercents = allAnswers.map((item) => {
          if (item === 0) return 0;
          return (item * 100) / total;
        });
        percents.push(percentRound(allPercents));
      });

      setPercents(percents);
    }
  }, [answers]);

  const changePage = (carouselItemIndex, pageNum) => {
    setCommentsLoading(true);

    service
      .qrVoteQuestionComments(
        pageNum > 1
          ? {
              ...dataForBack,
              commentsId: commentsPoll[carouselItemIndex]?.id,
              data: {
                ordering: getOrdering(),
                created_at_before: dateTo,
                created_at_after: dateFrom,
                limit: commentsPerPage,
                offset: pageNum * commentsPerPage - commentsPerPage
              }
            }
          : {
              ...dataForBack,
              commentsId: commentsPoll[carouselItemIndex]?.id,
              data: {
                ordering: getOrdering(),
                created_at_before: dateTo,
                created_at_after: dateFrom,
                limit: commentsPerPage
              }
            }
      )
      .then((resp) => {
        if (resp.status === 200) {
          const newComments = [...comments];
          newComments[index] = resp.data.results;
          setComments(newComments);
          setCommentsLoading(false);
        }
      });
  };

  const sortByDate = () => {
    const createdAt = createTimeOrdering ? 'created_at' : '-created_at';
    setCreateTimeOrdering(!createTimeOrdering);
    setCommentsLoading(true);
    service
      .qrVoteQuestionComments({
        ...dataForBack,
        commentsId: commentsPoll[index]?.id,
        data: {
          ordering: createdAt,
          created_at_before: dateTo,
          created_at_after: dateFrom,
          limit: commentsPerPage
        }
      })
      .then((resp) => {
        if (resp.status === 200) {
          const newCount = [...count];
          newCount[index] = resp.data.count;
          setCount(newCount);
          const newCommentsAmount = [...commentsAmount];
          newCommentsAmount[index] = resp.data.count;
          setCommentsAmount(newCommentsAmount);
          const newComments = [...comments];
          newComments[index] = resp.data.results;
          setComments(newComments);
          const newTrigger = [...triggerForChangingPage];
          newTrigger[index] = newTrigger[index] + 1;
          setTriggerForChangingPage(newTrigger);
          setCommentsLoading(false);
        }
      });
  };

  const onSliderFunc = (e) => {
    setIndex(e);
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" className="modal-responses">
      <div className="modal-content modal-dialog-scrollable">
        <div className="modal-header">
          <h5>{answersTitles[index]}</h5>
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onHide()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <Carousel
          slide
          controls={answers.length > 1}
          activeIndex={index}
          interval={null}
          onSelect={(e) => onSliderFunc(e)}
        >
          {answersTitles.map((item, i) => {
            return (
              <Carousel.Item key={i}>
                <AdditionalAnswerModalCarouselItem
                  answersLoading={answersLoading}
                  answers={answers[i]}
                  percents={percents[i]}
                  count={count[i]}
                  commentsLoading={commentsLoading}
                  commentsAmount={commentsAmount[i]}
                  comments={comments[i]}
                  indexOfCaruselItem={i}
                  sortByDate={sortByDate}
                  changePage={changePage}
                  triggerForChangingPage={triggerForChangingPage[i]}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </Modal>
  );
};

export default AdditionalAnswersModal;

AdditionalAnswersModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  data: PropTypes.shape({})
};

AdditionalAnswersModal.defaultProps = {
  show: false,
  onHide: null,
  data: null
};
