import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PrivacyTerms = () => {
  const terms = window.location.href.includes('/terms');
  const { i18n } = useTranslation();
  let currentLang = i18n.language;

  if (!terms) {
    if (currentLang === 'ukr') {
      return (
        <div className="privacy-terms">
          <h2 className="privacy-terms__label">Політика конфіденційності</h2>
          <h3 className="privacy-terms__label">УГОДА ПРО КОНФІДЕНЦІЙНІСТЬ</h3>
          <h3 className="privacy-terms__label">(ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ)</h3>
          <p>
            Ця угода про конфіденційність розроблена відповідно до вимог Закону України № 2297 від 01.06.2010р. «Про
            захист персональних даних» та інших нормативних актів українського законодавства, що регламентують правові
            відносини, пов'язані зі збиранням, обробкою, зберіганням персональних даних, а також правом громадян на
            невтручання у особисте життя та правом на самовираження.
          </p>
          <h4 className="privacy-terms__label">Загальна частина</h4>
          <p>
            ФОП Теньков І.В. (далі – Правовласник), дбаючи про розвиток взаємовідносин з клієнтами та користувачами
            веб-сайту, розуміючи важливість забезпечення охорони Ваших персональних даних, з метою вирішення можливих
            протиріч та непорозумінь підготував цю Угоду про конфіденційність (політику конфіденційності), далі за
            текстом – «Політика», та умови користування веб-сайту https://liketo.me, а також програми для мобільних
            пристроїв та планшетів, далі за текстом – «веб-сайт».
          </p>
          <p>
            Будь ласка, уважно прочитайте цю сторінку, інформація, яка викладена на ній, є важливою для Вас як для
            користувача веб-сайту та всіх його послуг та сервісів.
          </p>
          <p>
            Просимо Вас звернути увагу, що згідно з умовами Політики конфіденційності при Вашому відвідуванні ресурсів
            даного веб-порталу Ви підтверджуєте свою згоду з правилами та вимогами Політики конфіденційності та Угоди
            користувача (публічної оферти) LIKETO.ME. У разі Вашої незгоди з вимогами Політики конфіденційності та Угоди
            користувача (публічної оферти) LIKETO.ME. Ви зобов'язані залишити даний веб-сайт і утриматися від його
            відвідування в майбутньому.
          </p>
          <p>
            Увага! Користувач не має права розміщувати інформацію, використовувати веб-сайт, якщо він не згоден з
            Політикою конфіденційності, або якщо він не досяг установленого законодавством віку, коли він має право
            укладати угоди, або обмежений у своїх правах згідно із законом, або не є уповноваженою особою компанії , від
            імені якої розміщується інформація.
          </p>
          <p>
            Зберігання Ваших персональних даних буде проводитись Тенькова І.В., з використанням дата центру компанії
            SmartDC у Роттердам, Нідерланди.
          </p>
          <p>
            Ця Політика конфіденційності діє з «30» грудня 2021 року та оновлюватиметься в міру необхідності та/або
            внаслідок змін чинного законодавства. Політика конфіденційності завжди знаходиться на сторінці за адресою{' '}
            <a href="https://liketo.me/privacy">https://liketo.me/privacy</a>.
          </p>
          <h4 className="privacy-terms__label">Ваші права як суб'єкта персональних даних</h4>
          <p>
            Звертаємо увагу, що Ваші права як суб'єкта персональних даних передбачені статтею 8 Закону України №2297 від
            01.06.2010р. "Про захист персональних даних".
          </p>
          <p>Стаття 8. Права суб'єкта персональних даних:</p>
          <ol>
            <li>
              Особисті немайнові права на персональні дані, які має кожна фізична особа, є невід'ємними та непорушними.
            </li>
            <li>
              Суб'єкт персональних даних має право:
              <ul>
                <li>
                  знати про місцезнаходження бази персональних даних, що містить його персональні дані, її призначення
                  та найменування, місцезнаходження, та/або місце проживання (перебування) власника або розпорядника
                  персональних даних або дати відповідне доручення щодо отримання цієї інформації уповноваженим їм
                  особам, крім випадків, встановлених законом;
                </li>
                <li>
                  отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх
                  осіб, яким передаються його персональні дані;
                </li>
                <li>на доступ до своїх персональних даних;</li>
                <li>
                  отримувати не пізніше ніж за тридцять календарних днів з дня надходження запиту, крім випадків,
                  передбачених законом, відповідь про те, чи зберігаються його персональні дані у відповідній базі
                  персональних даних, а також отримувати зміст його персональних даних, що зберігаються;
                </li>
                <li>
                  пред'являти мотивовану вимогу власнику персональних даних із запереченням проти обробки своїх
                  персональних даних;
                </li>
                <li>
                  пред'являти мотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким власником та
                  розпорядником персональних даних, якщо ці дані обробляються незаконно або є недостовірними;
                </li>
                <li>
                  на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження
                  у зв'язку з навмисним приховуванням, ненаданням або несвоєчасним їх наданням, а також на захист від
                  надання відомостей, які є недостовірними або ганьблять честь, гідність та ділову репутацію фізичної
                  особи;
                </li>
                <li>
                  звертатися зі скаргами на обробку своїх персональних даних до органів державної влади та посадових
                  осіб, до повноважень яких належить забезпечення захисту персональних даних, або до суду;
                </li>
                <li>
                  застосовувати засоби правового захисту у разі порушення законодавства про захист персональних даних;
                </li>
                <li>
                  вносити застереження щодо обмеження права на обробку своїх персональних даних під час надання згоди;
                </li>
                <li>відкликати згоду на обробку персональних даних;</li>
                <li>знати механізм автоматичного оброблення персональних даних;</li>
                <li>на захист від автоматизованого рішення, яке має для нього правові наслідки.</li>
              </ul>
            </li>
            <li>
              Розпорядження персональними даними фізичної особи, обмеженої у цивільній дієздатності або визнаної
              недієздатною, здійснює її законний представник.
            </li>
          </ol>
          <h4 className="privacy-terms__label">Хто має доступ до Вашої інформації:</h4>
          <p>ФОП Теньков І.В. надає доступ до Вашої інформації:</p>
          <ul>
            <li>
              Незалежним третім особам, які надають різні послуги правовласнику, включаючи бізнес-аналіз, роботу з
              покупцями, маркетинг, забезпечення опитувань, запобігання шахрайству. Правовласник може дозволити третім
              особам збирати інформацію для своєї мети, наприклад, для роботи Веб-сайту або розсилки актуальної реклами.
              Треті особи мають доступ і можуть збирати лише необхідну для надання послуги інформацію і при цьому не
              мають права використовувати її з будь-якою іншою метою.
            </li>
            <li>
              Діловим партнерам, з якими Правовласник спільно надає продукти та послуги. Ви завжди можете визначити, які
              треті особи надають той чи інший продукт чи послугу, тому що їх найменування завжди будуть вказані поруч
              із Правовласником. Якщо Ви вирішите використовувати ці додаткові послуги, ми можемо надати нашим партнерам
              доступ до Вашої інформації, у тому числі персональних даних. Пам'ятайте, що правовласник не контролює
              захист вашої інформації такими діловими партнерами.
            </li>
            <li>
              Посиланням веб-сайтам. Якщо Ви заходите на Веб-сайт https://liketo.me за посиланням з іншого веб-сайту,
              Правовласник може надати цьому веб-сайту доступ до Вашої реєстраційної інформації, наприклад Вашого імені,
              поштової та електронної адреси, номера телефону. Цей веб-сайт отримує повний доступ до Вашої інформації,
              тому ми рекомендуємо Вам ознайомитися з політикою конфіденційності відповідного веб-сайту, перш ніж
              переходити з цього веб-сайту на https://liketo.me
            </li>
            <li>
              Кросплатформенним месенджерам, стороннім сайтам, сервісам прийому платежів. З нашого Веб-сайту Ви можете
              зайти на низку месенджерів, сторонніх сайтів, сервісів прийому платежів. Переходячи на ці веб-сайти, Ви
              надаєте їм свої персональні дані, і захист такої інформації буде здійснюватись відповідно до політики вже
              цих веб-сайтів. На цих веб-сайтах також можна змінити налаштування конфіденційності інформації.
            </li>
            <li>
              Компаніям усередині групи. Правовласник може надати Ваші персональні дані та іншу інформацію афілійованим
              компаніям. Спільне використання даних дозволяє нам забезпечити Вас інформацією про продукти та послуги,
              які можуть Вас зацікавити. При використанні інформації афілійовані компанії дотримуються поточної Політики
              конфіденційності.
            </li>
          </ul>
          <p>Також Правовласник може надати доступ до інформації:</p>
          <ul>
            <li>
              За порядком, розпорядженням суду або іншим судовим наказом; для затвердження та здійснення наших прав; для
              захисту при правових претензіях; а також в інших випадках, якщо цього вимагає закон. При цьому
              правовласник зберігає за собою право заявити будь-яке заперечення або скористатися будь-яким правом,
              наданим йому за законом, або відмовитися від цього.
            </li>
            <li>
              У випадках, коли це необхідно для отримання відомостей, запобігання та вжиття заходів щодо незаконних або
              передбачуваних незаконних дій; щоб захистити свої права, власність чи безпеку.
            </li>
            <li>
              У зв'язку з корпоративними угодами, як, наприклад, вилучення активів, злиття, продаж активів або
              малоймовірно банкрутства.
            </li>
          </ul>
          <p>
            Також Правовласник може надати загальну чи анонімну інформацію третім особам, зокрема рекламодавцям та
            інвесторам. Наприклад, правовласник може повідомити рекламодавцям кількість відвідувачів веб-сайту. Ця
            інформація не містить жодних персональних даних і використовується для розвитку веб-сайту.
          </p>
          <h4 className="privacy-terms__label">Цілі збору Ваших персональних даних:</h4>
          <p>
            Однією з цілей створення та функціонування веб-сайту Правовласника є інформування Вас про те, хто ми такі та
            чим займаємось. Правовласник збирає та використовує персональні дані (у тому числі ПІБ, місто Вашого
            проживання, номер телефону, електронну пошту та інше) для кращого надання Вам необхідних послуг та
            інформації.
          </p>
          <p>Тому Правовласник може використовувати Ваші персональні дані, щоб:</p>
          <ul>
            <li>відповідати на Ваші запитання та запити;</li>
            <li>обробляти Ваші замовлення та заявки на надання послуг;</li>
            <li>
              регулювати або іншим способом виконувати свої зобов'язання у зв'язку з будь-якою угодою, яку Ви уклали;
            </li>
            <li>запобігати та вирішувати проблеми, пов'язані з будь-якими послугами, які надає Вам;</li>
            <li>створювати продукти чи послуги, що відповідають Вашим потребам;</li>
            <li>оперативно реагувати на можливі Ваші скарги, пов'язані з вадами наданих Вам послуг.</li>
            <li>
              для оптимізації своїх послуг Правовласник може використовувати Ваші персональні дані під час прямого
              маркетингу (у тому числі надавати інформацію про товари та послуги, які надалі можуть бути цікавими для
              Вас шляхом надсилання SMS та електронних повідомлень, телефонного дзвінка);
            </li>
          </ul>
          <p>
            Крім випадків, встановлених цією політикою конфіденційності, Правовласник не розкриватиме будь-яку вашу
            персональну інформацію, якщо тільки не буде зобов'язаний зробити це за законом (наприклад, якщо це буде
            потрібно в ході судового процесу, або з метою запобігання шахрайству або іншого злочину), або якщо вважатиме
            , що така дія необхідна для захисту та/або захисту її прав, власності або особистої безпеки, а також
            аналогічно щодо наших користувачів/клієнтів та інших осіб.
          </p>
          <p>
            Будьте впевнені, що правовласник не буде використовувати вашу інформацію в будь-яких цілях, якщо ви
            повідомите, що не бажаєте, щоб правовласник використовував вашу інформацію таким чином, коли інформація
            надається, або пізніше.
          </p>
          <h4 className="privacy-terms__label">Безпека Ваших персональних даних:</h4>
          <p>
            Правовласник цінує Вашу персональну інформацію і забезпечує достатній рівень захисту. Ми використовуємо
            технології, спрямовані на захист Ваших конфіденційних даних від несанкціонованого доступу та неналежного
            використання. Ми регулярно оновлюємо ці заходи, враховуючи появу нових технологій.
          </p>
          <h4 className="privacy-terms__label">Використання Вашої персональної інформації на інших сайтах:</h4>
          <p>
            Ми не несемо відповідальності за політику конфіденційності та практичні методики на інших сайтах, на які ми
            посилаємося або з яких ви переходите на наш сайт. Будь ласка, ознайомтеся з політикою кожного відвіданого
            сайту, щоб краще зрозуміти свої права та обов'язки. Якщо у Вас є сумніви або питання, зверніться до власника
            або оператора цього сайту.
          </p>
          <h4 className="privacy-terms__label">Політика щодо файлів cookie та використання Google Analytics:</h4>
          <p>
            Під час роботи нашого сайту ми використовуємо файли cookie для збереження налаштувань користувачів та інших
            відомостей, що полегшують користування сайтом. Ми також використовуємо функцію ремаркетингу та Google
            Analytics для збору статистики про відвідування порталу. Ви можете налаштувати браузер так, щоб відхилити
            всі файли cookie або отримувати сповіщення про їхнє надсилання.
          </p>
          <p>
            Будь ласка, зверніть увагу, що веб-маяки, що знаходяться на веб-сайтах, які Ви відвідуєте, не фіксують Вашу
            особисту інформацію, а допомагають нам робити сайт максимально зручним та корисним для Вас.
          </p>
          <p>Використання цієї інформації допомагає нам підтримувати, захищати та розвивати наш сервіс.</p>
          <h4 className="privacy-terms__label">Обмеження згідно з Політикою конфіденційності</h4>
          <p>
            Відповідно до умов Політики конфіденційності при користуванні веб-сайтом Ви повинні дотримуватись таких
            правил:
          </p>
          <ul>
            <li>
              не використовуйте веб-сайт належним чином. Зокрема, не намагайтеся впливати чи втручатися у його роботу чи
              отримати доступ до інформації не через стандартний інтерфейс;
            </li>
            <li>використовуйте веб-сайт лише відповідно до норм українського законодавства;</li>
          </ul>
          <p>
            Якщо Ви порушуватимете цю Політику конфіденційності або якщо Правовласник обґрунтовано запідозрить Вас у
            цьому, він може призупинити або повністю закрити Вам доступ до веб-сайту.
          </p>
          <p>
            Правовласник може у будь-який час переглянути цю Політику конфіденційності та оновити її. Ви маєте
            зобов'язання щодо цих змін і повинні відвідувати цю сторінку час від часу, щоб переглянути поточну редакцію
            Політики конфіденційності.
          </p>
          <h4 className="privacy-terms__label">Додаткова інформація та застереження</h4>
          <p>
            Правовласник вживає заходів, щоб інформація на цьому сайті була достовірною. Проте Правовласник не бере на
            себе відповідальність і не гарантує, що надана інформація та дані є точними, об'єктивними та актуальними на
            момент перегляду.
          </p>
          <p>
            Цей веб-сайт загалом, та окремі його елементи такі як (включно, але не обмежуючись) елементи дизайну,
            авторські фотоматеріали, статті, програмний код, бази даних охороняються Законом України "Про авторське
            право та суміжні права", Міжнародними нормативно-правовими актами , та іншими актами законодавства України.
            Будь-яке інше використання, крім перегляду, завантаження та роздруківок сторінок порталу для особистого,
            некомерційного використання, заборонено. Копіювання інформації з сайту може бути здійснено лише з
            некомерційною метою для розміщення на сторінках користувача, у блогах, соціальних мережах з посиланням на
            першоджерело.
          </p>
          <p>
            Забороняється розповсюджувати, змінювати, передавати, користуватися або використовувати повторно будь-яку
            інформацію з даного сайту для будь-якої публічної чи комерційної мети без письмового дозволу Правовласника.
            Ви повинні дотримуватися всіх авторських прав та інших вимог щодо власності на завантажені та/або копіювані
            матеріали. Скопійована інформація повинна містити посилання на цей сайт як джерело.
          </p>
          <p>
            Правовласник не несе відповідальності за будь-які прямі та непрямі збитки, понесені у зв'язку зі сплатою
            штрафних санкцій, або будь-які збитки та витрати, як понесені в результаті укладання правової угоди,
            інформацію про об'єкт якої було розміщено на даному сайті.
          </p>
          <p>Фактично використання Вами ресурсу відбувається на Ваш власний ризик.</p>
          <p>
            У межах, які максимально дозволяє закон, ні Правовласник, ні будь-яка інша сторона, яка залучена до
            створення, виробництва або обслуговування сайту, не несе відповідальності за пряме, непряме або випадкове
            ушкодження, що будь-коли наносилися, та які були викликані тим, що Ви мали доступ до цього сайту,
            користувалися ним або сподівалися на нього, навіть якщо ми попереджали Вас про можливість таких пошкоджень
            та втрат.
          </p>
          <p>
            ФОП Теньков І.В. у жодному разі не буде причетним до жодної зі сторін прямого, непрямого, навмисного або
            ненавмисного заподіяння збитків за будь-яке використання цього веб-сайту, або на будь-якому іншому
            взаємопов'язаному сайті, включаючи, без обмеження, будь-які втрати доходів, гудвілу, втрату програм або
            інших даних у Вашій системі обробки інформації, навіть якщо правовласник був швидко поінформований про
            можливість таких збитків.
          </p>
          <p>
            Під час роботи з веб-сайтом Вам не надаються права на інтелектуальну власність ні на сам веб-сайт, ні на
            його зміст, ні на будь-які елементи брендингу або логотипи веб-сайту.
          </p>
          <p>
            Я погоджуюсь з Політикою конфіденційності та Угодою користувача (публічною офертою) веб-сайтом, а також з
            передачею та обробкою моїх даних.
          </p>
          <p>Я підтверджую своє повноліття, а також відсутність будь-яких актів, що обмежують мою дієздатність.</p>
        </div>
      );
    }
    if (currentLang === 'ru') {
      return (
        <div className="privacy-terms">
          <h2 className="privacy-terms__label">Политика конфиденциальности</h2>
          <h3 className="privacy-terms__label">СОГЛАШЕНИЕ О КОНФИДЕНЦИАЛЬНОСТИ</h3>
          <h3 className="privacy-terms__label">(ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ)</h3>
          <p>
            Это соглашение о конфиденциальности разработано в соответствии с требованиями Закона Украины № 2297 от
            01.06.2010р. "О защите персональных данных" и других нормативных актов украинского законодательства,
            регламентирующих правовые отношения, связанные со сбором, обработкой, хранением персональных данных, а также
            правом граждан на невмешательство в личную жизнь и правом на самовыражение.
          </p>
          <h4 className="privacy-terms__label">Общая часть</h4>
          <p>
            ФЛП Теньков И.В. (далее - Правообладатель), заботясь о развитии взаимоотношений с клиентами и пользователями
            сайта, понимая важность обеспечения охраны Ваших персональных данных, с целью решения возможных противоречий
            и недоразумений подготовил это Соглашение о конфиденциальности (политику конфиденциальности), далее по
            тексту - "Политика", и условия пользования сайта <a href="https://liketo.me">https://liketo.me</a>, а также
            программы для мобильных устройств и планшетов, далее по тексту - "веб-сайт".
          </p>
          <p>
            Пожалуйста, внимательно прочитайте эту страницу, информация, изложенная на ней, является важной для Вас как
            для пользователя сайта и всех его услуг и сервисов.
          </p>
          <p>
            Просим Вас обратить внимание, что согласно условиям Политики конфиденциальности при Вашем посещении ресурсов
            данного веб-портала Вы подтверждаете свое согласие с правилами и требованиями Политики конфиденциальности и
            Пользовательского соглашения (публичной оферты) LIKETO.ME. В случае Вашего несогласия с требованиями
            Политики конфиденциальности и Пользовательского соглашения (публичной оферты) LIKETO.ME. Вы обязаны покинуть
            данный веб-сайт и воздержаться от его посещения в будущем.
          </p>
          <p>
            Пользователь не имеет права размещать информацию, использовать веб-сайт, если он не согласен с Политикой
            конфиденциальности, или если он не достиг установленного законодательством возраста, когда он имеет право
            заключать сделки, или ограничен в своих правах по закону, или не является уполномоченным лицом компании, от
            имени которой размещается информация.
          </p>
          <p>
            Хранение Ваших персональных данных будет проводиться Теньков И.В., с использованием дата центра компании
            SmartDC в Роттердам, Нидерланды.
          </p>
          <p>
            Эта Политика конфиденциальности действует с "30" декабря 2021 года и будет обновляться по мере необходимости
            и/или в результате изменений действующего законодательства. Политика конфиденциальности всегда находится на
            странице по адресу <a href="https://liketo.me">https://liketo.me</a>.
          </p>
          <h4 className="privacy-terms__label">Ваши права как субъекта персональных данных</h4>
          <p>
            Обращаем внимание, что Ваши права как субъекта персональных данных предусмотрены статьей 8 Закона Украины
            №2297 от 01.06.2010р. "О защите персональных данных":
          </p>
          <p> "Статья 8. Права субъекта персональных данных:</p>
          <p>
            1. Личные неимущественные права на персональные данные, которые имеет каждое физическое лицо, являются
            неотъемлемыми и нерушимыми.
          </p>
          <p> 2. Субъект персональных данных имеет право:</p>
          <ul>
            <li>
              знать о местонахождении базы персональных данных, содержащей его персональные данные, ее назначение и
              наименование, местонахождение и/или место жительства (пребывания) владельца или распорядителя персональных
              данных или дать соответствующее поручение о получении этой информации уполномоченным им лицам, кроме
              случаев, установленных законом;
            </li>
            <li>
              получать информацию об условиях предоставления доступа к персональным данным, в частности информацию о
              третьих лицах, которым передаются его персональные данные;
            </li>
            <li>на доступ к своим персональным данным;</li>
            <li>
              получать не позднее чем за тридцать календарных дней со дня поступления запроса, кроме случаев,
              предусмотренных законом, ответ о том, хранятся ли его персональные данные в соответствующей базе
              персональных данных, а также получать содержание его персональных данных, которые хранятся;
            </li>
            <li>
              предъявлять мотивированное требование владельцу персональных данных с возражением против обработки своих
              персональных данных;
            </li>
            <li>
              предъявлять мотивированное требование об изменении или уничтожении своих персональных данных любым
              владельцем и распорядителем персональных данных, если эти данные обрабатываются незаконно или являются
              недостоверными;
            </li>
            <li>
              на защиту своих персональных данных от незаконной обработки и случайной потери, уничтожения, повреждения в
              связи с умышленным сокрытием, непредоставлением или несвоевременным их предоставлением, а также на защиту
              от предоставления сведений, которые являются недостоверными или порочащими честь, достоинство и деловую
              репутацию физического лица;
            </li>
            <li>
              обращаться с жалобами на обработку своих персональных данных в органы государственной власти и должностных
              лиц, к полномочиям которых относится обеспечение защиты персональных данных, или в суд;
            </li>
            <li>
              применять средства правовой защиты в случае нарушения законодательства о защите персональных данных;
            </li>
            <li>
              вносить оговорки об ограничении права на обработку своих персональных данных при предоставлении согласия;
            </li>
            <li>отозвать согласие на обработку персональных данных;</li>
            <li>знать механизм автоматической обработки персональных данных;</li>
            <li>на защиту от автоматизированного решения, которое имеет для него правовые последствия.</li>
          </ul>
          <p>
            3. Распоряжение персональными данными физического лица, ограниченного в гражданской дееспособности или
            признанного недееспособным, осуществляет его законный представитель."
          </p>
          <h4 className="privacy-terms__label">Кто имеет доступ к Вашей информации:</h4>
          <p>ФОП Тенькова І.В. надає доступ до Вашої інформації:</p>
          <ul>
            <li>
              Независимым третьим лицам, которые предоставляют различные услуги правообладателю, включая бизнес-анализ,
              работу с покупателями, маркетинг, обеспечение опросов, предотвращение мошенничества. Правообладатель также
              может разрешить третьим лицам собирать информацию для своей цели, например, для работы Веб-сайта или
              рассылки актуальной рекламы. Третьи лица имеют доступ и могут собирать только необходимую для
              предоставления услуги информацию и при этом не имеют права использовать ее с любой другой целью.
            </li>
            <li>
              Деловым партнерам, с которыми Правообладатель совместно предоставляет продукты и услуги. Вы всегда можете
              определить, какие третьи лица предоставляют тот или иной продукт или услугу, потому что их наименования
              всегда будут указаны рядом с Правообладателем. Если Вы решите использовать эти дополнительные услуги, мы
              можем предоставить нашим партнерам доступ к Вашей информации, в том числе персональных данных. Помните,
              что правообладатель не контролирует защиту вашей информации такими деловыми партнерами.
            </li>
            <li>
              Которые ссылаются веб-сайтам. Если Вы заходите на Веб-сайт{' '}
              <a href="https://liketo.me">https://liketo.me</a> по ссылке с другого веб-сайта, Правообладатель может
              предоставить этому веб-сайту доступ к Вашей регистрационной информации, например Вашего имени, почтового и
              электронного адреса, номера телефона. Этот веб-сайт получает полный доступ к Вашей информации, поэтому мы
              рекомендуем Вам ознакомиться с политикой конфиденциальности соответствующего веб-сайта, прежде чем
              переходить с этого веб-сайта на https:// liketo.me
            </li>
            <li>
              Кроссплатформенным мессенджерам, сторонним сайтам, сервисам приема платежей. С нашего Веб-сайта Вы можете
              зайти на ряд мессенджеров, сторонних сайтов, сервисов приема платежей. Переходя на эти веб-сайты, Вы
              предоставляете им свои персональные данные, и защита такой информации будет осуществляться в соответствии
              с политикой уже этих веб-сайтов. На этих веб-сайтах также можно изменить настройки конфиденциальности
              информации.
            </li>
            <li>
              Компаниям внутри группы. Правообладатель может предоставить Ваши персональные данные и другую информацию
              аффилированным компаниям. Совместное использование данных позволяет нам обеспечить Вас информацией о
              продуктах и услугах, которые могут Вас заинтересовать. При использовании информации аффилированные
              компании придерживаются текущей Политики конфиденциальности.
            </li>
          </ul>
          <p>Также Правообладатель может предоставить доступ к информации:</p>
          <ul>
            <li>
              По порядку, распоряжению суда или иному судебному приказу; для утверждения и осуществления наших прав; для
              защиты при правовых претензиях; а также в других случаях, если этого требует закон. При этом
              правообладатель сохраняет за собой право заявить любое возражение или воспользоваться любым правом,
              предоставленным ему по закону, или отказаться от этого.
            </li>
            <li>
              В случаях, когда это необходимо для получения сведений, предотвращения и принятия мер в отношении
              незаконных или предполагаемых незаконных действий; чтобы защитить свои права, собственность или
              безопасность.
            </li>
            <li>
              В связи с корпоративными сделками, как, например, изъятие активов, слияние, продажа активов или
              маловероятно банкротства.
            </li>
            <li>
              У зв'язку з корпоративними угодами, як, наприклад, вилучення активів, злиття, продаж активів або
              малоймовірно банкрутства.
            </li>
          </ul>
          <p>
            Также Правообладатель может предоставить общую или анонимную информацию третьим лицам, в частности
            рекламодателям и инвесторам. Например, правообладатель может сообщить рекламодателям количество посетителей
            веб-сайта. Эта информация не содержит никаких персональных данных и используется для развития веб-сайта.
          </p>
          <h4 className="privacy-terms__label">Цели сбора Ваших персональных данных</h4>
          <p>
            Одной из целей создания и функционирования сайта Правообладателя является информирование Вас о том, кто мы
            такие и чем занимаемся. Правообладатель собирает и использует персональные данные (в том числе ФИО, город
            Вашего проживания, номер телефона, электронную почту и прочее) для лучшего предоставления Вам необходимых
            услуг и информации.
          </p>
          <p>Поэтому Правообладатель может использовать Ваши персональные данные, чтобы:</p>
          <ul>
            <li>отвечать на Ваши вопросы и запросы;</li>
            <li>обрабатывать Ваши заказы и заявки на предоставление услуг;</li>
            <li>
              регулировать или иным образом выполнять свои обязательства в связи с любым соглашением, которое Вы
              заключили;
            </li>
            <li>предотвращать и решать проблемы, связанные с любыми услугами, которые предоставляет Вам;</li>
            <li>создавать продукты или услуги, соответствующие Вашим потребностям;</li>
            <li>
              оперативно реагировать на возможные Ваши жалобы, связанные с недостатками предоставленных Вам услуг.
            </li>
            <li>
              для оптимизации своих услуг Правообладатель может использовать Ваши персональные данные во время прямого
              маркетинга (в том числе предоставлять информацию о товарах и услугах, которые в дальнейшем могут быть
              интересными для Вас путем отправки SMS и электронных сообщений, телефонного звонка);
            </li>
            <li>
              Кроме случаев, установленных этой политикой конфиденциальности, Правообладатель не будет раскрывать любую
              вашу персональную информацию, если только не будет обязан сделать это по закону (например, если это
              потребуется в ходе судебного процесса, или с целью предотвращения мошенничества или иного преступления),
              или если посчитает, что такое действие необходимо для защиты и/или защиты его прав, собственности или
              личной безопасности, а также аналогично в отношении наших пользователей/клиентов и других лиц.
            </li>
            <li>
              Будьте уверены, что правообладатель не будет использовать вашу информацию в любых целях, если вы сообщите,
              что не желаете, чтобы правообладатель использовал вашу информацию таким образом, когда информация
              предоставляется, или позже.
            </li>
          </ul>
          <h4 className="privacy-terms__label">Безопасность ваших персональных данных</h4>
          <p>
            Правообладатель ценит вашу персональную информацию, поэтому обеспечивает достаточный уровень ее защиты.
            Поэтому Правообладатель реализовал технологии, направленные на защиту ваших конфиденциальных данных от
            несанкционированного доступа и ненадлежащего использования, и соответствующим образом будет обновлять эти
            меры при появлении новых технологий.
          </p>
          <h4 className="privacy-terms__label">Использование вашей персональной информации на других сайтах</h4>
          <p>
            На сайте Правообладателя могут быть ссылки на другие сайты или вы можете перейти на наш сайт по ссылке с
            другого сайта. Как вы понимаете, мы не можем отвечать за политики конфиденциальности и практические методики
            на других сайтах.
          </p>
          <p>
            Такой контент охватывается соответствующими условиями использования, а также любыми дополнительными
            правилами и информацией о конфиденциальности, предоставляемой в связи с использованием стороннего сайта.
          </p>
          <p>
            Правообладатель рекомендует вам изучить политику каждого посещаемого сайта, чтобы лучше понять свои права и
            обязанности, особенно когда вы предоставляете на этих сайтах контент любого типа. Если у вас есть какие-то
            сомнения или вопросы, свяжитесь с владельцем или оператором такого сайта.
          </p>
          <h4 className="privacy-terms__label">Политика в отношении файлов cookie, использование Google Analytics</h4>
          <p>
            Во время работы сайта Правообладателем используются файлы cookie. С их помощью возможна идентификация
            браузера пользователей, которые посещают наш портал повторно, в этих файлах возможно сохранение настроек
            пользователей и других сведений. Для удобства пользования сайтом мы используем функцию ремаркетинга. При
            работе с этой функцией также используются файлы cookie.
          </p>
          <p>Можно настроить браузер так, чтобы отклонять все файлы cookie или оповещать об их отправке.</p>
          <p>
            С помощью Google Analytics Правообладатель собирает статистику о посещении портала, такую как посещаемые
            страницы, количество просмотров страниц, домены, Интернет-провайдеров и страны происхождения наших
            посетителей, а также адреса сайтов, посещенных до и после портала и тому подобное. Тем не менее, ни одно из
            этих действий не связано с Вами лично и измеряется только в совокупности. Веб-маяки - это невидимые файлы,
            которые находятся на веб-сайтах, которые Вы посещаете. В случае, если Вы посещаете страницу сайта,
            содержащего веб-маяк, она обращается к Вашему компьютеру для того, чтобы определить, посещали ли Вы ранее
            эту страницу, видели ли Вы ранее определенную рекламу. Веб-маяки не фиксируют Ваш электронный адрес или
            любую другую личную информацию.
          </p>
          <p>
            Использование этой информации помогает Правообладателю делать сайт максимально удобным и полезным для Вас,
            поддерживать, защищать и развивать сервисы сайта.
          </p>
          <h4 className="privacy-terms__label">Ограничения согласно Политике конфиденциальности</h4>
          <p>
            В соответствии с условиями Политики конфиденциальности при пользовании веб-сайтом Вы должны соблюдать
            следующие правила:
          </p>
          <ul>
            <li>
              не используйте веб-сайт должным образом. В частности, не пытайтесь влиять или вмешиваться в его работу или
              получить доступ к информации не через стандартный интерфейс;
            </li>
            <li>используйте веб-сайт только в соответствии с нормами украинского законодательства;</li>
          </ul>
          <p>
            Если Вы будете нарушать эту Политику конфиденциальности или если Правообладатель обоснованно заподозрит Вас
            в этом, он может приостановить или полностью закрыть Вам доступ к веб-сайту.
          </p>
          <p>
            Правообладатель может в любое время пересмотреть эту Политику конфиденциальности и обновить ее. Вы имеете
            обязательства по этим изменениям и должны посещать эту страницу время от времени, чтобы просмотреть текущую
            редакцию Политики конфиденциальности.
          </p>
          <h4 className="privacy-terms__label">Дополнительная информация и предостережения</h4>
          <p>
            Правообладатель принимает меры, чтобы информация на этом сайте была достоверной. Однако Правообладатель не
            берет на себя ответственность и не гарантирует, что предоставленная информация и данные являются точными,
            объективными и актуальными на момент просмотра.
          </p>
          <p>
            Этот веб-сайт в целом, и отдельные его элементы такие как (включая, но не ограничиваясь) элементы дизайна,
            авторские фотоматериалы, статьи, программный код, базы данных охраняются Законом Украины "Об авторском праве
            и смежных правах", Международными нормативно-правовыми актами, и другими актами законодательства Украины.
            Любое другое использование, кроме просмотра, загрузки и распечаток страниц портала для личного,
            некоммерческого использования, запрещено. Копирование информации с сайта может быть осуществлено только с
            некоммерческой целью для размещения на страницах пользователя, в блогах, социальных сетях со ссылкой на
            первоисточник.
          </p>
          <p>
            Запрещается распространять, изменять, передавать, пользоваться или использовать повторно любую информацию с
            данного сайта для любой публичной или коммерческой цели без письменного разрешения Правообладателя. Вы
            должны соблюдать все авторские права и другие требования в отношении собственности на загруженные и/или
            копируемые материалы. Скопированная информация должна содержать ссылку на этот сайт как источник.
          </p>
          <p>
            Правообладатель не несет ответственности за любые прямые и косвенные убытки, понесенные в связи с уплатой
            штрафных санкций, или любые убытки и расходы, понесенные в результате заключения правового соглашения,
            информация об объекте которого была размещена на данном сайте.
          </p>
          <p>Фактически использование Вами ресурса происходит на Ваш собственный риск.</p>
          <p>
            В пределах, которые максимально позволяет закон, ни Правообладатель, ни любая другая сторона, которая
            привлечена к созданию, производству или обслуживанию сайта, не несет ответственности за прямое, косвенное
            или случайное повреждение, которые когда-либо наносились, и которые были вызваны тем, что Вы имели доступ к
            этому сайту, пользовались им или надеялись на него, даже если мы предупреждали Вас о возможности таких
            повреждений и потерь.
          </p>
          <p>
            ФЛП Теньков И.В. ни в коем случае не будет причастным ни к одной из сторон прямого, косвенного, умышленного
            или неумышленного причинения убытков за любое использование этого сайта, или на любом другом взаимосвязанном
            сайте, включая, без ограничения, любые потери доходов, гудвилла, потерю программ или других данных в Вашей
            системе обработки информации, даже если правообладатель был быстро проинформирован о возможности таких
            убытков.
          </p>
          <p>
            При работе с веб-сайтом Вам не предоставляются права на интеллектуальную собственность ни на сам веб-сайт,
            ни на его содержание, ни на любые элементы брендинга или логотипы веб-сайта.
          </p>
          <p>
            Я согласен с Политикой конфиденциальности и Пользовательским соглашением (публичной офертой) веб-сайтом, а
            также с передачей и обработкой моих данных.
          </p>
          <p>
            Я подтверждаю свое совершеннолетие, а также отсутствие каких-либо актов, ограничивающих мою дееспособность.
          </p>
        </div>
      );
    }
    return (
      <div className="privacy-terms">
        <h2 className="privacy-terms__label">Privacy Policy - Liketo.me</h2>
        <h3 className="privacy-terms__label">Data collected on the basis of consent</h3>
        <p>
          Upon your request and expression of consent, we collect the following data for the purpose of providing
          services to you. Your data is not used for any other purposes or shared with third parties, besides the
          company on whose behalf we provide data collection services. It is removed upon your withdrawal of consent or
          your request to terminate these services.
        </p>
        <h4 className="privacy-terms__label">Comments</h4>
        <p>
          Name, email address, phone number, rating, content of the comment: this data is collected when you leave a
          comment and displayed on the Website, and shared with the company on whose behalf we provide data collection
          services.
        </p>
        <p>
          If you leave a comment on the Website, your name, phone number, and email address will also be saved in
          cookies. These are for your convenience so that you do not have to fill in your details again when you leave
          another comment. These cookies will be saved on your computer until you delete them.
        </p>
        <p>IP and browser user agent string: this data is collected when you leave a comment.</p>
        <p>
          Retention period: the aforementioned data is retained indefinitely so we can recognize and approve any
          follow-up comments automatically instead of holding them in a moderation queue.
        </p>
        <h4 className="privacy-terms__label">Statistics</h4>
        <p>
          The website uses a minimal build of Google Analytics, a service which transmits website traffic data to Google
          servers in the United States and allows us to notice trends to improve the user experience on our website.
          This minimal build processes personal data such as: the unique User ID set by Google Analytics, the date and
          time, the title of the page being viewed, the URL of the page being viewed, the URL of the page that was
          viewed prior to the current page, the screen resolution, the time in local timezone, the files that were
          clicked on and downloaded, the links clicked on to an outside domain, the type of device, and the country,
          region, and city.
        </p>
        <p>You may opt out of this tracking at any time by activating the “Do Not Track” setting in your browser.</p>
        <h4 className="privacy-terms__label">Embedded content from other websites</h4>
        <p>
          Articles on the Website may include embedded content (e.g. videos, charts, etc.). Embedded content from other
          websites behaves in the exact same way as if the visitor had visited the other website.
        </p>
        <p>
          These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor
          your interaction with that embedded content, including tracing your interaction with the embedded content if
          you have an account and are logged in to that website.
        </p>
        <h4 className="privacy-terms__label">Your rights pertaining your data</h4>
        <p>
          If you have left comments on the Website, you can request to receive an exported file of the personal data we
          hold about you, including any data you have provided to us. You can also request that we rectify or erase any
          personal data we hold about you. Please send your request to{' '}
          <a href="mailto:mail.liketome@gmil.com">mail.liketome@gmil.com</a>
        </p>
        <ul>
          <li>The right to withdraw consent</li>
          <li>The right of access</li>
          <li>The right to erasure</li>
          <li>The right to rectification</li>
          <li>The right to data portability</li>
          <li>The right to object</li>
          <li>Notification of data breaches</li>
          <li>The right to lodge a complaint with a supervisory authority</li>
        </ul>
        <h4 className="privacy-terms__label">Exercising your rights</h4>
        <p>Except as explicitly provided herein, please use the following contact information for privacy inquiries:</p>
        <p>Individual entrepreneur Tenkov I.V.</p>
        <p>
          Or send your request to <a href="mailto:mail.liketome@gmil.com">mail.liketome@gmil.com</a>
        </p>
      </div>
    );
  }
  if (currentLang === 'ukr') {
    return (
      <div className="privacy-terms">
        <h2 className="privacy-terms__label">Умови користування</h2>
        <h4 className="privacy-terms__label">Загальні положення</h4>
        <p>
          1.1 Цей веб-сайт під доменним ім'ям <a href="https://liketo.me/">https://liketo.me/</a> ("Веб-сайт") належить
          та управляється ФОП Тенькова І.В. («Liketo.me» або «Сервіс», «ми», або, відповідно, «нас», «наш»або інакше, в
          залежності від контексту), компанією, зареєстрованою відповідно до законодавства України.
        </p>
        <p>
          1.2 Ці умови використання («Умови Використання»), а також документи, згадані в них, викладають умови
          використання та застосовуються до використання всього вмісту Веб-сайту, а також до будь-якого електронного
          листування між Вами та Liketo.me. Використання веб-сайту означає доступ, перегляд або реєстрацію для
          використання веб-сайту.
        </p>
        <p>
          1.3 Наші сповіщення про конфіденційність та сповіщення про файли cookie є невід'ємною частиною даних Умов
          Використання. Просимо Вас уважно їх прочитати, щоб зрозуміти, як ми збираємо та обробляємо Ваші персональні
          дані.
        </p>
        <p>
          1.4 Ці Умови Використання є угодою між Liketo.me та Вами щодо доступу, використання та перегляду Веб-сайту.
        </p>
        <p>
          1.5 При доступі до цього Веб-сайту Ви підтверджуєте своє розуміння того, що такий доступ та використання
          регулюються Умовами використання. Якщо Ви не погоджуєтесь з будь-якою частиною Умов Використання, Ви повинні
          припинити використання цього Веб-сайту та залишити його.
        </p>
        <p>
          1.6 Час від часу, у ці Умови Використання нами можуть вносити правки та оновлення. Ми рекомендуємо Вам
          регулярно переглядати наші Умови використання. Якщо Ви не погоджуєтесь зі зміненими Умовами Використання, Ви
          повинні припинити використання Веб-сайту. Умови Використання не можуть бути змінені або будь-якою особою від
          Вашого імені. Ніщо зі сказаного чи зробленого будь-якою стороною від нашого імені не розглядатиметься як
          будь-яка зміна Умов Використання, опублікованих на цьому Веб-сайті.
        </p>
        <p>
          1.7 Ми також можемо час від часу оновлювати або змінювати наш Веб-сайт або його вміст, але ми не стверджуємо,
          що Веб-сайт завжди буде опублікований в останній версії.
        </p>
        <h4 className="privacy-terms__label">Права Інтелектуальної Власності</h4>
        <p>
          2.1 За винятком контенту, завантаженого Вами, Liketo.me є власником або ліцензіатом будь-якої та всієї
          текстової, візуальної, аудіо або іншої інформації, даних, контенту, дизайну, ілюстрацій, фотографій, звуку,
          відео, музики, графіки, компіляції та інших матеріалів, що складені , або завантажені на Веб-сайт або іншим
          чином доступні на Веб-сайті в розділі (контент).
        </p>
        <p>
          2.2 За винятком випадків, прямо передбачених у цьому документі або іншим чином дозволених обов'язковими
          положеннями чинного законодавства, Ви погоджуєтесь з тим, що не будете використовувати, копіювати, замінювати,
          змінювати, переконструювати, відтворювати, створювати, публікувати, транслювати, розповсюджувати, зберігати,
          переміщати , передавати, комерційно експлуатувати чи іншим чином використовувати будь-який паперовий чи
          цифровий Контент без отримання попередньої прямої письмової згоди від Liketo.me.
        </p>
        <p>
          2.3 Без шкоди для вищевикладеного, Ви можете використовувати Контент лише в особистих, некомерційних цілях за
          умови, що наші авторські права та наш статус (і статус будь-яких ідентифікованих авторів) як авторів такого
          Контенту завжди підтверджуються, а інші повідомлення про права власності відображаються належним чином.
        </p>
        <p>
          2.4 Якщо Ви використовуєте Контент, порушуючи Умови Використання, або всупереч іншій нашій прямій згоді, Ви
          порушуєте ці Умови Використання та наші авторські права та/або авторські права наших ліцензіарів, а також інші
          права інтелектуальної власності. У разі порушення цих Умов Використання Вам більше не дозволяється
          користуватися Веб-сайтом і Ви повинні знищити будь-який матеріал, який Ви завантажили або роздрукували з
          Веб-сайту. Ми однозначно залишаємо за собою право на звернення до суду відповідно до договору та
          законодавства.
        </p>
        <p>
          2.5 Всі товарні знаки, знаки обслуговування та торгові назви на Веб-сайті є нашою власністю або власністю
          наших партнерів та захищені українським законодавством про авторське право та товарні знаки. Ви не повинні
          використовувати будь-які з цих товарних знаків, знаків обслуговування або торгових найменувань без попередньої
          згоди.
        </p>
        <h4 className="privacy-terms__label">Контент користувача</h4>
        <p>
          3.1 Будь-яка інформація, матеріали або дані, які Ви передаєте на наш Веб-сайт, крім особистої інформації, що
          підпадає під Повідомлення про конфіденційність, будуть вважатися не конфіденційними та непатентованими
          («Користувачський Контент») і у нас не буде жодних зобов'язань щодо таких матеріалів . Liketo.me може вільно
          копіювати, розкривати, розповсюджувати, включати та іншим чином використовувати такий Контент користувача та
          будь-яку іншу текстову, візуальну, аудіо- або іншу інформацію для будь-яких комерційних або некомерційних
          цілей.
        </p>
        <p>
          3.2 Ви не повинні передавати на Веб-сайт будь-який Користувальницький Контент, який носить загрозливий,
          наклепницький, образливий, порнографічний, лайливий, дискримінативний характер, може викликати расову
          ненависть, порушує довіру або права особистості, або який вважатиметься кримінальним злочином, або спричинить
          за собою кримінальний злочин, або спричинить цивільно-правову відповідальність, або іншим чином порушить якесь
          національне чи міжнародне право. Крім того, Вам забороняється передавати на Веб-сайт будь-який Контент
          користувача, який будь-яким чином технічно шкідливий або іншим чином неправомірно використовувати Веб-сайт.
        </p>
        <h4 className="privacy-terms__label">Посилання на / з інших Веб-сайтів</h4>
        <p>
          4.1 Наш Веб-сайт може містити посилання або модулі, що підключаються, до інших веб-сайтів і служб, які
          обслуговуються та підтримуються не Liketo.me, а іншими третіми незалежними сторонами. Якщо Ви використовуєте
          ці посилання або модулі, Ви будете перенаправлені на ці сторонні веб-сайти та залишите наш Веб-сайт.
          Використовуючи ці посилання або модулі, Ви визнаєте, розумієте та погоджуєтесь з тим, що ми не контролюємо та
          не несемо відповідальності чи зобов'язань, прямих чи непрямих, за ці веб-сайти, їх зміст чи доступність. Крім
          того, ви визнаєте та розумієте, що ці веб-сайти можуть розміщувати файли cookie у вашому браузері на вашому
          комп'ютері або мобільному пристрої, як описано в нашому повідомленні про cookie. Revizion не несе
          відповідальності за будь-які збитки, прямі чи непрямі, викликані або ймовірно викликані або пов'язані з
          використанням від будь-якого контенту, продуктів або послуг, доступних на таких сторонніх веб-сайтах.
        </p>
        <p>4.2 Ви можете посилатися на цей Веб-сайт з будь-якого іншого веб-сайту за умови, що Ви не будете:</p>
        <p>4.2.1. копіювати цей Веб-сайт, будь-яку з його сторінок або будь-який Контент із Веб-сайту;</p>
        <p>
          4.2.2. спотворювати будь-яку Вашу взаємодію з нами або будь-яким чином заявляти, що ми чи будь-яка з наших
          афілійованих осіб схвалюємо будь-які Ваші продукти чи послуги;
        </p>
        <p>
          4.2.3. використовувати наш товарний знак, логотип або будь-який матеріал, якщо ми не надали Вам письмової
          згоди;
        </p>
        <p>
          4.3. Веб-сайт, з якого Ви посилаєтеся на нас, не повинен містити Контент, який би ображав, суперечив чи
          порушував будь-які права інтелектуальної власності третьої особи або іншим чином порушував будь-які
          національні чи міжнародні закони.
        </p>
        <p>
          4.4. Ми повністю залишаємо за собою можливість анулювати право на авторизацію, надане Вам у пункті 2 вище,
          якщо Ви порушуєте ці Умови використання. Авторизація, надання якої передбачено пунктом 4.2, не передбачає
          будь-якої відповідальності Liketo.me щодо посилань на наш Веб-сайт. Liketo.me виключає будь-яку таку
          відповідальність.
        </p>
        <h4 className="privacy-terms__label">Відмова від Гарантії</h4>
        <p>
          5.1. Хоча ми прагнемо своєчасно оновлювати та забезпечувати повноту та правильність Контенту, що міститься на
          Сайті та сам Веб-сайт, Веб-сайт та всі матеріали, містяться на Веб-сайті надаються «як є» і, в тій мірі, як це
          дозволено застосовними законами , ми не представляємо та не гарантуємо точність, повноту, правильність,
          доступність, надійність, придатність будь-якого Контенту чи самого Веб-сайту. Ми знімаємо з себе
          відповідальність стосовно всіх таких заяв, гарантій, а також умов, термінів, зобов'язань, явних або на увазі,
          заснованих на законах або звичаях, включаючи, крім іншого, задовільну якість, придатність для конкретної мети,
          відсутність порушень і використання запобіжного заходу або умінь, які з ходу ділових відносин чи ходу
          виконань.
        </p>
        <p>
          5.2. Крім того, ми не гарантуємо, що на Веб-сайті немає вірусів або інших шкідливих компонентів (включаючи,
          окрім іншого, віруси, троянських програм, мережевих черв'яків, пошкоджені дані тощо). Усі витрати на
          обслуговування, ремонт або виправлення вашого обладнання, даних, програмного забезпечення або пристрою несете
          тільки Ви, незалежно від того, чи є такі витрати результатом, або будь-яким чином пов'язані з будь-яким
          використанням Контенту з нашого Веб-сайту чи самого Веб-сайту.
        </p>
        <h4 className="privacy-terms__label">Обмеження відповідальності</h4>
        <p>
          6.1 У тій мірі, в якій це дозволено застосовним законодавством, Liketo.me та всі її філії, ліцензіари,
          співробітники, консультанти, партнери, посадові особи, директори, акціонери не несуть відповідальності за
          будь-які прямі чи опосередковані, понесені чи поставлені, випадкові чи штрафні збитки, втрату вигоди, доходу,
          шкоду діловим інтересам, інвестиціям, репутації, втрату даних, прибутку, довіри з боку контрагента та третьої
          сторони, прогнозованого та непрогнозованого, незалежно від того, чи має місце наслідок правопорушення,
          виконання зобов'язань за договором, незалежно від змісту даного Веб-сайту, пов'язаного з даним Веб-сайтом, або
          будь-якого Контенту, розміщеного на ньому, або будь-якої інформації, розміщеної будь-яким способом, а також
          незалежно від будь-яких інших веб-сайтів, посилання на які містить даний Веб-сайт, або матеріалів, розміщених
          на таких веб-сайтах.
        </p>
        <h4 className="privacy-terms__label">Компенсація</h4>
        <p>
          7.1. Ви повинні без будь-яких обмежень звільнити Liketo.me та всі його філії, ліцензіарів, співробітників,
          консультантів, партнерів, посадових осіб, директорів, акціонерів від будь-яких претензій третіх сторін від
          відповідальності, збитків, збитків, витрат або витрат, включаючи, але не обмежуючись: всі розумні витрати та
          витрати на адвокатів, що виникають або пов'язані з використанням цього Веб-сайту або будь-якого його Контенту
          або з будь-яким порушенням цих Умов Використання, незаконного присвоєння або порушення будь-яких прав третьої
          сторони або будь-яких національних або міжнародних застосовних прав.
        </p>
        <h4 className="privacy-terms__label">Інші умови</h4>
        <p>
          8.1 Якщо будь-яка частина цих Умов Використання буде визнана незаконною, недійсною або з будь-якої причини
          нездійсненною у будь-якому відношенні відповідно до законів будь-якої застосовної юрисдикції, така частина
          Умов Використання втрачає силу в межах цих Умов Використання і не впливає на дійсність та виконання будь-якого
          іншого положення у цьому документі.
        </p>
        <p>
          8.2 Ці Умови Використання регулюються та тлумачаться відповідно до законів України. Усі суперечки, що
          виникають у зв'язку із цими Умовами Використання, підпадають під виняткову юрисдикцію судів України.
        </p>
        <p>Дякуємо за відвідування нашого Веб-сайту!</p>
      </div>
    );
  }
  if (currentLang === 'ru') {
    return (
      <div className="privacy-terms">
        <h2 className="privacy-terms__label">Условия пользования</h2>
        <h4 className="privacy-terms__label">Общие положения</h4>
        <p>
          1.1 Этот веб-сайт под доменным именем <a href="https://liketo.me/">https://liketo.me/</a> ("Веб-сайт")
          принадлежит и управляется ФЛП Теньков И.В. ("Liketo.me" или "Сервис", "мы", или, соответственно, "нас", "наш"
          или иначе, в зависимости от контекста), компанией, зарегистрированной в соответствии с законодательством
          Украины.
        </p>
        <p>
          1.2 Эти условия использования ("Условия использования"), а также документы, упомянутые в них, излагают условия
          использования и применяются к использованию всего содержимого Веб-сайта, а также к любой электронной переписке
          между Вами и Liketo.me. Использование веб-сайта означает доступ, просмотр или регистрацию для использования
          веб-сайта.
        </p>
        <p>
          1.3 Наши уведомления о конфиденциальности и уведомления о файлах cookie являются неотъемлемой частью данных
          Условий использования. Просим Вас внимательно их прочитать, чтобы понять, как мы собираем и обрабатываем Ваши
          персональные данные.
        </p>
        <p>
          1.4 Эти Условия использования являются соглашением между Liketo.me и Вами по доступу, использованию и
          просмотру Веб-сайта.
        </p>
        <p>
          1.5 При доступе к этому Веб-сайту Вы подтверждаете свое понимание того, что такой доступ и использование
          регулируются Условиями использования. Если Вы не согласны с любой частью Условий использования, Вы должны
          прекратить использование этого Веб-сайта и оставить его.
        </p>
        <p>
          1.6 Время от времени, в эти Условия Использования нами могут вносить правки и обновления. Мы рекомендуем Вам
          регулярно просматривать наши Условия использования. Если Вы не согласны с измененными Условиями использования,
          Вы должны прекратить использование Веб-сайта. Условия использования не могут быть изменены или любым лицом от
          Вашего имени. Ничто из сказанного или сделанного любой стороной от нашего имени не будет рассматриваться как
          любое изменение Условий использования, опубликованных на этом Веб-сайте.
        </p>
        <p>
          1.7 Мы также можем время от времени обновлять или изменять наш Веб-сайт или его содержимое, но мы не
          утверждаем, что Веб-сайт всегда будет опубликован в последней версии.
        </p>
        <h4 className="privacy-terms__label">Права Интеллектуальной Собственности</h4>
        <p>
          2.1 За исключением контента, загруженного Вами, Liketo.me является владельцем или лицензиатом любой и всей
          текстовой, визуальной, аудио или другой информации, данных, контента, дизайна, иллюстраций, фотографий, звука,
          видео, музыки, графики, компиляции и других материалов, составленные или загруженные на Веб-сайт или иным
          образом доступны на Веб-сайте в разделе (контент).
        </p>
        <p>
          2.2 За исключением случаев, прямо предусмотренных в этом документе или иным образом разрешенных обязательными
          положениями действующего законодательства, Вы соглашаетесь с тем, что не будете использовать, копировать,
          заменять, изменять, переконструировать, воспроизводить, создавать, публиковать, транслировать, распространять,
          хранить, перемещать, передавать, коммерчески эксплуатировать или иным образом использовать любой бумажный или
          цифровой Контент без получения предварительного прямого письменного согласия от Liketo.me
        </p>
        <p>
          2.3 Без ущерба для вышеизложенного, Вы можете использовать Контент только в личных, некоммерческих целях при
          условии, что наши авторские права и наш статус (и статус любых идентифицированных авторов) как авторов такого
          Контента всегда подтверждаются, а другие сообщения о правах собственности отображаются должным образом.
        </p>
        <p>
          2.4 Если Вы используете Контент, нарушая Условия использования, или вопреки другому нашему прямому согласию,
          Вы нарушаете эти Условия использования и наши авторские права и/или авторские права наших лицензиаров, а также
          другие права интеллектуальной собственности. В случае нарушения этих Условий Использования Вам больше не
          разрешается пользоваться Веб-сайтом и Вы должны уничтожить любой материал, который Вы загрузили или
          распечатали с Веб-сайта. Мы однозначно оставляем за собой право на обращение в суд в соответствии с договором
          и законодательством.
        </p>
        <p>
          2.5 Все товарные знаки, знаки обслуживания и торговые названия на Веб-сайте являются нашей собственностью или
          собственностью наших партнеров и защищены украинским законодательством об авторском праве и товарных знаках.
          Вы не должны использовать любые из этих товарных знаков, знаков обслуживания или торговых наименований без
          предварительного согласия.
        </p>
        <h4 className="privacy-terms__label">Контент пользователя</h4>
        <p>
          3.1 Любая информация, материалы или данные, которые Вы передаете на наш Веб-сайт, кроме личной информации,
          подпадающей под Сообщение о конфиденциальности, будут считаться не конфиденциальными и непатентованными
          ("Пользовательский Контент") и у нас не будет никаких обязательств в отношении таких материалов. Liketo.me
          может свободно копировать, раскрывать, распространять, включать и иным образом использовать такой
          Пользовательский Контент и любую другую текстовую, визуальную, аудио- или другую информацию для любых
          коммерческих или некоммерческих целей.
        </p>
        <p>
          3.2 Вы не должны передавать на Веб-сайт любой Пользовательский Контент, который носит угрожающий,
          клеветнический, оскорбительный, порнографический, бранный, дискриминационный характер, может вызвать расовую
          ненависть, нарушает доверие или права личности, или который будет считаться уголовным преступлением, или
          повлечет за собой уголовное преступление, или повлечет гражданско-правовую ответственность, или иным образом
          нарушит какое-то национальное или международное право. Кроме того, Вам запрещается передавать на Веб-сайт
          любой Контент пользователя, который каким-либо образом технически вреден или иным образом неправомерно
          использовать Веб-сайт.
        </p>
        <h4 className="privacy-terms__label">Ссылки на / с других Веб-сайтов</h4>
        <p>
          4.1 Наш Веб-сайт может содержать ссылки или подключаемые модули к другим веб-сайтам и службам, которые
          обслуживаются и поддерживаются не Liketo.me, а другими третьими независимыми сторонами. Если Вы используете
          эти ссылки или модули, Вы будете перенаправлены на эти сторонние веб-сайты и оставите наш Веб-сайт. Используя
          эти ссылки или модули, Вы признаете, понимаете и соглашаетесь с тем, что мы не контролируем и не несем
          ответственности или обязательств, прямых или косвенных, за эти веб-сайты, их содержание или доступность. Кроме
          того, вы признаете и понимаете, что эти веб-сайты могут размещать файлы cookie в вашем браузере на вашем
          компьютере или мобильном устройстве, как описано в нашем сообщении о cookie. Revizion не несет ответственности
          за любые убытки, прямые или косвенные, вызванные или вероятно вызванные или связанные с использованием от
          любого контента, продуктов или услуг, доступных на таких сторонних веб-сайтах.
        </p>
        <p>4.2 Вы можете ссылаться на этот Веб-сайт с любого другого веб-сайта при условии, что Вы не будете:</p>
        <p>4.2.1. копировать этот Веб-сайт, любую из его страниц или любой Контент с Веб-сайта;</p>
        <p>
          4.2.2. искажать любое Ваше взаимодействие с нами или любым образом заявлять, что мы или любое из наших
          аффилированных лиц одобряем любые Ваши продукты или услуги;
        </p>
        <p>
          4.2.3. использовать наш товарный знак, логотип или любой материал, если мы не предоставили Вам письменного
          согласия;
        </p>
        <p>
          4.3. Веб-сайт, с которого Вы ссылаетесь на нас, не должен содержать Контент, который бы оскорблял,
          противоречил или нарушал любые права интеллектуальной собственности третьего лица или иным образом нарушал
          любые национальные или международные законы.
        </p>
        <p>
          4.4. Мы полностью оставляем за собой возможность аннулировать право на авторизацию, предоставленное Вам в
          пункте 2 выше, если Вы нарушаете эти Условия использования. Авторизация, предоставление которой предусмотрено
          пунктом 4.2, не предусматривает какой-либо ответственности Liketo.me в отношении ссылок на наш Веб-сайт.
          Liketo.me исключает любую такую ответственность.
        </p>
        <h4 className="privacy-terms__label">Отказ от Гарантии</h4>
        <p>
          5.1. Хотя мы стремимся своевременно обновлять и обеспечивать полноту и правильность Контента, содержащегося на
          Сайте и сам Веб-сайт, Веб-сайт и все материалы, содержащиеся на Веб-сайте предоставляются "как есть" и, в той
          мере, как это разрешено применимыми законами, мы не представляем и не гарантируем точность, полноту,
          правильность, доступность, надежность, пригодность любого Контента или самого Веб-сайта. Мы снимаем с себя
          ответственность в отношении всех таких заявлений, гарантий, а также условий, сроков, обязательств, явных или
          подразумеваемых, основанных на законах или обычаях, включая, помимо прочего, удовлетворительное качество,
          пригодность для конкретной цели, отсутствие нарушений и использование меры предосторожности или умений,
          которые из хода деловых отношений или хода исполнений.
        </p>
        <p>
          5.2. Кроме того, мы не гарантируем, что на Веб-сайте нет вирусов или других вредоносных компонентов (включая,
          помимо прочего, вирусы, троянских программ, сетевых червей, поврежденные данные и т.д.). Все расходы на
          обслуживание, ремонт или исправление вашего оборудования, данных, программного обеспечения или устройства
          несете только Вы, независимо от того, являются ли такие расходы результатом, или каким-либо образом связаны с
          любым использованием Контента с нашего Веб-сайта или самого Веб-сайта.
        </p>
        <h4 className="privacy-terms__label">Ограничение ответственности</h4>
        <p>
          6.1 В той мере, в какой это разрешено применимым законодательством, Liketo.me и все ее филиалы, лицензиары,
          сотрудники, консультанты, партнеры, должностные лица, директора, акционеры не несут ответственности за любые
          прямые или косвенные, понесенные или поставленные, случайные или штрафные убытки, потерю выгоды, дохода, ущерб
          деловым интересам, инвестициям, репутации, потерю данных, прибыли, доверия со стороны контрагента и третьей
          стороны, прогнозируемого и непрогнозируемого, независимо от того, имеет ли место следствие правонарушения,
          выполнения обязательств по договору, независимо от содержания данного Веб-сайта, связанного с данным
          Веб-сайтом, или любого Контента, размещенного на нем, или любой информации, размещенной любым способом, а
          также независимо от любых других веб-сайтов, ссылки на которые содержит данный Веб-сайт, или материалов,
          размещенных на таких веб-сайтах.
        </p>
        <h4 className="privacy-terms__label">Компенсация</h4>
        <p>
          7.1. Вы должны без каких-либо ограничений освободить Liketo.me и все его филиалы, лицензиаров, сотрудников,
          консультантов, партнеров, должностных лиц, директоров, акционеров от любых претензий третьих сторон от
          ответственности, убытков, ущерба, расходов или затрат, включая, но не ограничиваясь: все разумные расходы и
          расходы на адвокатов, возникающие или связанные с использованием этого Веб-сайта или любого его Контента или с
          любым нарушением этих Условий использования, незаконного присвоения или нарушения любых прав третьей стороны
          или любых нац.
        </p>
        <h4 className="privacy-terms__label">Другие условия</h4>
        <p>
          8.1 Если любая часть этих Условий использования будет признана незаконной, недействительной или по любой
          причине невыполнимой в любом отношении в соответствии с законами любой применимой юрисдикции, такая часть
          Условий использования теряет силу в рамках этих Условий использования и не влияет на действительность и
          выполнение любого другого положения в этом документе.
        </p>
        <p>
          8.2 Эти Условия Использования регулируются и толкуются в соответствии с законами Украины. Все споры,
          возникающие в связи с этими Условиями использования, подпадают под исключительную юрисдикцию судов Украины.
        </p>
        <p>Благодарим за посещение нашего Веб-сайта!</p>
      </div>
    );
  }
  return (
    <div className="privacy-terms">
      <h2 className="privacy-terms__label">Terms of Use</h2>
      <h4 className="privacy-terms__label">General terms</h4>
      <p>
        1.1 This website under the domain name <a href="https://liketo.me/">https://liketo.me/</a> (the "Website") is
        owned and operated by FOP Tenkov I.V. ("Liketo.me" or "Service", "we", or, respectively, "us", "our" or
        otherwise, as the context requires), a company registered under the laws of Ukraine.
      </p>
      <p>
        1.2 These terms of use (the "Terms of Use"), as well as the documents referred to in them, set forth the terms
        of use and apply to the use of all content on the Website, as well as to any electronic correspondence between
        you and Liketo.me. Using the Website means accessing, browsing or registering to use the Website.
      </p>
      <p>
        1.3 Our privacy notice and cookie notice are an
        integral part of these Terms of Use. Please read them carefully to understand how we collect and process your
        personal data.
      </p>
      <p>
        1.4 These Terms of Use constitute the entire agreement between Liketo.me and you regarding your access, use and
        browsing of the Website.
      </p>
      <p>
        1.5 By accessing this Website, you acknowledge your understanding that such access and use is governed by the
        Terms of Use. If you do not agree with any part of the Terms of Use, you must stop using this Website and leave
        it.
      </p>
      <p>
        1.6 From time to time, we may make changes and updates to these Terms of Use. We encourage you to review our
        Terms of Use on a regular basis. If you do not agree to the amended Terms of Use, you must stop using the
        Website. The Terms of Use may not be modified by or on behalf of you. Nothing said or done by any party on our
        behalf will be deemed to constitute any modification of the Terms of Use posted on this Website.
      </p>
      <p>
        1.7 We may also update or change our Website or its content from time to time, but we do not represent that the
        Website will always be published in the latest version.
      </p>
      <h4 className="privacy-terms__label">Intellectual Property Rights</h4>
      <p>
        2.1 Except for content uploaded by you, Liketo.me is the owner or licensee of any and all text, visual, audio or
        other information, data, content, design, illustrations, photographs, sound, video, music, graphics, compilation
        and other materials compiled or uploaded to the Website or otherwise available on the Website under the
        (content) section.
      </p>
      <p>
        2.2 Except as expressly provided herein or as otherwise permitted by mandatory provisions of applicable law, you
        agree that you will not use, copy, replace, modify, reverse engineer, reproduce, create, publish, broadcast,
        distribute, store, transmit, transfer, commercially exploit or otherwise exploit any paper or digital Content
        without the prior express written consent of Liketo.me.
      </p>
      <p>
        2.3 Without prejudice to the foregoing, you may only use the Content for your personal, non-commercial use,
        provided that our copyright and our status (and that of any identified authors) as the authors of such Content
        is always acknowledged and other proprietary notices are appropriately displayed.
      </p>
      <p>
        2.4 If you use the Content in violation of the Terms of Use, or contrary to our other express consent, you will
        violate these Terms of Use and our copyrights and/or the copyrights of our licensors, as well as other
        intellectual property rights. If you violate these Terms of Use, you are no longer permitted to use the Website
        and you must destroy any material that you have downloaded or printed from the Website. We expressly reserve the
        right to take legal action in accordance with the contract and the law.
      </p>
      <p>
        2.5 All trademarks, service marks and trade names on the Website are our property or the property of our
        partners and are protected by Ukrainian copyright and trademark laws. You must not use any of these trademarks,
        service marks or trade names without our prior consent.
      </p>
      <h4 className="privacy-terms__label">User Content</h4>
      <p>
        3.1 Any information, materials or data that you transmit to our Website, other than personal information covered
        by the Privacy Notice, will be considered non-confidential and non-proprietary ("User Content") and we will have
        no obligation with respect to such materials. Liketo.me is free to copy, disclose, distribute, incorporate and
        otherwise use such User Content and any other textual, visual, audio or other information for any commercial or
        non-commercial purpose.
      </p>
      <p>
        3.2 You must not transmit to the Website any User Content that is threatening, defamatory, abusive,
        pornographic, profane, discriminatory, may cause racial hatred, violates the trust or rights of an individual,
        or that would be considered a criminal offense, or would give rise to a criminal offense, or give rise to civil
        liability, or otherwise violate any national or international law. In addition, you are prohibited from
        transmitting to the Website any User Content that is in any way technically harmful or otherwise misuses the
        Website.
      </p>
      <h4 className="privacy-terms__label">Links to/from other Websites</h4>
      <p>
        4.1 Our Website may contain links or plug-ins to other websites and services that are operated and maintained by
        third parties other than Liketo.me. If you use these links or modules, you will be redirected to those
        third-party websites and leave our Website. By using these links or modules, you acknowledge, understand and
        agree that we have no control over and assume no responsibility or liability, direct or indirect, for these
        websites, their content or availability. Furthermore, you acknowledge and understand that these websites may
        place cookies on your browser on your computer or mobile device as described in our cookie notice. Liketo.me
        will not be responsible or liable for any damage, direct or indirect, caused or alleged to be caused by or in
        connection with use of or reliance on any content, products or services available on or through such third-party
        websites.
      </p>
      <p>4.2 You may link to this Website from any other website provided that you do not:</p>
      <ol>
        <li>copy this Website, any of its pages or any Content from the Website;</li>
        <li>
          misrepresent any of your interactions with us or in any way represent that we or any of our affiliates endorse
          any of your products or services;
        </li>
        <li>use our trademark, logo or any material unless we have provided you with our written consent;</li>
      </ol>
      <p>
        4.3 The website from which you link to us must not contain Content that would offend, infringe or violate any
        third party intellectual property rights or otherwise violate any national or international laws.
      </p>
      <p>
        4.4 We fully reserve the right to revoke the authorization granted to you if you breach these Terms of Use. The
        authorization granted does not imply any liability on the part of Liketo.me in relation to links to our Website.
        Liketo.me excludes any such liability.
      </p>
      <h4 className="privacy-terms__label">Waiver of the Warranty</h4>
      <p>
        5.1 While we strive to update and ensure that the Content contained on the Website and the Website itself is
        complete and correct in a timely manner, the Website and all materials contained on the Website are provided "as
        is" and, to the extent permitted by applicable laws, we do not represent or warrant the accuracy, completeness,
        correctness, availability, reliability, suitability of any Content or the Website itself. We disclaim all
        liability with respect to all such representations, warranties, conditions, terms, obligations, whether express
        or implied, based on law or custom, including, but not limited to, satisfactory quality, fitness for a
        particular purpose, non-infringement and use of a course of dealing or course of performance.
      </p>
      <p>
        5.2 In addition, we do not warrant that the Website is free of viruses or other harmful components (including,
        but not limited to, viruses, trojan horses, worms, corrupted data, etc.). All costs for the maintenance, repair
        or correction of your equipment, data, software or device are the sole responsibility of you, whether or not
        such costs result from, or are in any way related to, any use of Content from our Website or the Website itself.
      </p>
      <h4 className="privacy-terms__label">Limitation of liability</h4>
      <p>
        6.1 To the extent permitted by applicable law, Liketo.me and all of its affiliates, licensors, employees,
        consultants, partners, officers, directors, shareholders shall not be liable for any direct or indirect,
        incurred or suffered, incidental or punitive damages, loss of profits, revenue, damage to business interests,
        investments, reputation, loss of data, profits, trust of the counterparty and third party, whether foreseeable
        or unforeseeable, whether arising out of tort, performance of contractual obligations, regardless of the content
        of this Website, the content linked to this Website, or any Content posted on this Website, or any information
        posted in any way, and regardless of any other websites linked to this Website or materials posted on such
        websites.
      </p>
      <h4 className="privacy-terms__label">Compensation</h4>
      <p>
        7.1 You shall, without limitation, indemnify Liketo.me and all of its affiliates, licensors, employees,
        consultants, partners, officers, directors, shareholders, and employees from and against any third-party claims,
        damages, losses, costs or expenses, including but not limited to: all reasonable costs and attorneys' fees,
        arising out of or related to your use of this Website or any of its Content or any violation of these Terms of
        Use, misappropriation or infringement of any third-party rights.
      </p>
      <h4 className="privacy-terms__label">Other terms and conditions</h4>
      <p>
        8.1 If any part of these Terms of Use is found to be illegal, invalid or for any reason unenforceable in any
        respect under the laws of any applicable jurisdiction, such part of the Terms of Use shall be ineffective to the
        extent of these Terms of Use and shall not affect the validity and enforceability of any other provision herein.
      </p>
      <p>
        8.2 These Terms of Use shall be governed by and construed in accordance with the laws of Ukraine. All disputes
        arising in connection with these Terms of Use shall be subject to the exclusive jurisdiction of the courts of
        Ukraine.
      </p>
      <p>Thank you for visiting our Website!</p>
    </div>
  );
};

export default PrivacyTerms;

PrivacyTerms.propTypes = {
  terms: PropTypes.bool
};

PrivacyTerms.defaultProps = {
  terms: false
};
