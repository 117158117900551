import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import usePagination from '../../hooks/usePagination';
import sortBy from 'lodash/sortBy';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import { ReactComponent as IconSort } from '../../img/sprite/svg/sort.svg';
import { ReactComponent as IconComment } from '../../img/sprite/svg/comment.svg';

const commentsPerPage = 5;

const AdditionalAnswerModalCarouselItem = ({
  answersLoading,
  answers,
  percents,
  count,
  commentsLoading,
  commentsAmount,
  comments,
  indexOfCaruselItem,
  sortByDate,
  triggerForChangingPage,
  changePage
}) => {
  const { t } = useTranslation();

  const { next, prev, jump, currentPage, setCurrentPage, maxPage } =
    usePagination(count || 1, commentsPerPage, indexOfCaruselItem);

  useEffect(() => {
    if (triggerForChangingPage > 0) setCurrentPage(1);
  }, [triggerForChangingPage]);

  const localChangePage = (pageNum) => {
    if (currentPage !== pageNum) {
      changePage(indexOfCaruselItem, pageNum);
    }
  };

  return (
    <>
      <div className="modal-body">
        {answersLoading && <Loading />}
        {!answersLoading && answers?.length > 0 && (
          <div className="questions">
            <table className="table">
              <thead>
                <tr>
                  <th className="questions-polls" scope="col">
                    {`${t('AdditionalAnswersModal.polls')}:`}
                  </th>
                  <th className="questions-quantity text-center" scope="col">
                    {`${t('AdditionalAnswersModal.quantity')}:`}
                  </th>
                  <th className="questions-percentage" scope="col">
                    {`${t('AdditionalAnswersModal.percent')}:`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortBy(answers, ['position']).map((item, i) => {
                  return (
                    <tr key={item?.id}>
                      <td className="questions-polls">{item?.title}</td>
                      <td className="questions-quantity text-center">
                        <b>{item?.count_positive_answers}</b>
                      </td>
                      <td className="questions-percentage">
                        <div className="d-flex align-items-center">
                          <div className="progress-questions mr-3">
                            <div
                              className="progress-questions__item"
                              style={{
                                width: `${percents[i]}%`
                              }}
                            />
                          </div>
                          {(percents[i] && <b>{percents[i]}%</b>) || <b>0%</b>}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="questions-polls">
                    <b> {`${t('AdditionalAnswersModal.total')}:`}</b>
                  </td>
                  <td className="questions-quantity text-center">
                    <b>{answers[0]?.count_total_answers || 0}</b>
                  </td>
                  <td className="questions-percentage" />
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <div className="comments">
          {(!commentsLoading || commentsAmount > 0) && (
            <div className="comments__head">
              <h5 className="comments__title">
                {`${t('AdditionalAnswersModal.comments')}: ${commentsAmount || 0}`}
              </h5>
              {commentsAmount > 1 && (
                <a className="sortable-link" role="button" onClick={sortByDate}>
                  {t('AdditionalAnswersModal.sortByDate')}
                  <IconSort className="svg-sprite-icon icon-sort" />
                </a>
              )}
            </div>
          )}
          {commentsLoading && <Loading />}
          {commentsAmount > 0 && !commentsLoading && (
            <div className="comments__list">
              {!commentsLoading &&
                comments.map((item) => {
                  return (
                    <div className="comment" key={item?.id}>
                      <div className="comment__head">
                        <span>
                          <span>
                            <IconComment className="svg-sprite-icon icon-comment" />
                          </span>
                          {moment(item?.created_at).format('DD/MM/YYYY')}
                        </span>
                      </div>
                      <div className="comment__text">{item?.body}</div>
                    </div>
                  );
                })}
            </div>
          )}
          <div className="comments__pagination">
            <Pagination
              key={indexOfCaruselItem}
              currentPage={currentPage}
              maxPage={maxPage}
              next={next}
              prev={prev}
              jump={jump}
              changePage={localChangePage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalAnswerModalCarouselItem;

AdditionalAnswerModalCarouselItem.propTypes = {
  answersLoading: PropTypes.bool,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
  percents: PropTypes.arrayOf(PropTypes.number),
  count: PropTypes.number || undefined,
  commentsLoading: PropTypes.bool,
  commentsAmount: PropTypes.number || undefined,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  indexOfCaruselItem: PropTypes.number,
  sortByDate: PropTypes.func,
  triggerForChangingPage: PropTypes.number,
  changePage: PropTypes.func
};
