import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import ContentEditable from './ContentEditable';
import QrFooter from './QrFooter';
import { ReactComponent as IconAlert } from '../../img/sprite/svg/alert.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import getUserCountry from 'js-user-country';
import translations from './translation.json';
import phoneLengths from './phoneLengths.json';

const QrQuestions = ({
  data,
  title,
  nextQuestions,
  onSubmitFunc,
  developedIn,
  lang
}) => {
  const [customAnswerDefaultText, setCustomAnswerDefaultText] = useState('');
  const [customAnswerText, setCustomAnswerText] = useState('');
  const [boolAnswers, setBoolAnswers] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [callMeOptionId, setCallMeOptionId] = useState(false);
  const [callMeChecked, setCallMeChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);

  useEffect(() => {
    // restore to default settings
    setCustomAnswerDefaultText('');
    setCustomAnswerText('');
    setBoolAnswers([]);
    setCallMeOptionId(false);
    setCallMeChecked(false);
    setPhoneNumber('');
    setUserName('');
    setPhoneNumberIsValid(false);

    const answers = [];
    const answerText = [];
    data.forEach((item) => {
      if (item.type === 'bool') answers.push(item);
      else if (item.type === 'text') answerText.push(item);
      else if (item.type === 'phone') {
        setCallMeOptionId(item.id);
        setCallMeChecked(true);
      }
    });
    if (answers.length) setBoolAnswers(answers);
    if (answerText.length) setCustomAnswerDefaultText(answerText[0]);
  }, [title]);

  useEffect(() => {
    if (customAnswerDefaultText?.title)
      setCustomAnswerText(customAnswerDefaultText.title);
  }, [customAnswerDefaultText]);

  const handleChange = (evt) => {
    // checkbox handle change
    setCustomAnswerText(evt.target.value);
  };

  useEffect(() => {
    // setting submit button disabled
    const oneOfBoolTrue = boolAnswers.some((item) => item.checked);
    const callMeValidate = callMeChecked
      ? callMeChecked && phoneNumberIsValid
      : false;
    if (
      oneOfBoolTrue ||
      (customAnswerText.length &&
        customAnswerText !== customAnswerDefaultText.title) ||
      callMeValidate
    ) {
      setSubmitDisabled(false);
    } else setSubmitDisabled(true);
  }, [boolAnswers, customAnswerText, callMeChecked, phoneNumber, title]);

  const handleFocus = () => {
    if (customAnswerText === customAnswerDefaultText.title) {
      setCustomAnswerText('');
      const element = document.getElementById('customAnswer');
      element.classList.add('has-feedback');
    }
  };

  const handleBlur = () => {
    if (customAnswerText === '') {
      setCustomAnswerText(customAnswerDefaultText.title);
      const element = document.getElementById('customAnswer');
      element.classList.remove('has-feedback');
    }
  };

  const checkboxOnChangeHandler = (id) => {
    const index = boolAnswers.findIndex((item) => item.id === id);
    const newBoolAnswers = cloneDeep(boolAnswers);
    newBoolAnswers[index].checked = !newBoolAnswers[index].checked;
    setBoolAnswers(newBoolAnswers);
  };

  const formatCustomAnswerText = (text) => {
    if (text) {
      if (text.endsWith('&nbsp;')) {
        text = text.substring(0, text.length - 7);
      }
    }
    const newText = text
      .replaceAll('&amp;', '&')
      .replaceAll('&gt;', '>')
      .replaceAll('&lt;', '<');
    return newText;
  };

  const pasteAsPlainText = (event) => {
    event.preventDefault();

    const text = event.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  const disableNewlines = (event) => {
    const keyCode = event.keyCode || event.which;

    if (keyCode === 13) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const onSubmitHandler = () => {
    const dataToBack = { questions: [] };
    boolAnswers.forEach((item) => {
      dataToBack.questions.push({
        poll_question_id: item.id,
        answer: {
          is_true: !!item?.checked
        }
      });
    });
    if (
      customAnswerText &&
      customAnswerText !== customAnswerDefaultText.title
    ) {
      dataToBack.questions.push({
        poll_question_id: customAnswerDefaultText.id,
        answer: {
          body: formatCustomAnswerText(customAnswerText)
        }
      });
      // deleteng focus from text input for custom answer
      const element = document.getElementById('customAnswer');
      if (element) element.classList.remove('has-feedback');
    }
    if (callMeChecked && callMeOptionId && phoneNumberIsValid) {
      dataToBack.questions.push({
        poll_question_id: callMeOptionId,
        answer: {
          body: `${phoneNumber}, ${userName}`
        }
      });
    }
    onSubmitFunc(dataToBack, nextQuestions.slice(1));
  };

  const handleOnPhoneChange = (value, data) => {
    setPhoneNumber(value);
    const withoutCode = value.slice(data.dialCode.length);
    setPhoneNumberIsValid(
      withoutCode.length === phoneLengths[data?.['countryCode']]['phoneLength']
    );
  };

  const inputStyle = {
    border: 'none',
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#485766',
    width: '100%',
    height: '100%',
    fontFamily: 'Montserrat, sans-serif'
  };

  return (
    <>
      <div className="container has-questions">
        <h1 className="page-qr__question-name">{title}</h1>
        <form
          className="qr-question-form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitHandler();
          }}
        >
          <div className="qr-question-form__questions">
            {boolAnswers.length > 0 &&
              sortBy(boolAnswers, ['position']).map((item) => {
                return (
                  <div className="qr-question-checkbox" key={item.id}>
                    <div className="qr-question-checkbox__input-wrap">
                      <label className="qr-question-checkbox__name">
                        <input
                          className="qr-question-checkbox__input"
                          type="checkbox"
                          name={item.id}
                          onChange={() => checkboxOnChangeHandler(item.id)}
                        />
                        <span className="qr-question-checkbox__name-text">
                          {item.title}
                        </span>
                      </label>
                    </div>
                  </div>
                );
              })}
          </div>
          {customAnswerDefaultText.title && (
            <div className="qr-question-form__custom-answer">
              <ContentEditable
                html={customAnswerText}
                id="customAnswer"
                className="qr-question-custom-answer"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={(e) => handleBlur(e)}
                onPaste={pasteAsPlainText}
                onKeyPress={disableNewlines}
              />
            </div>
          )}
          {callMeOptionId && (
            <div>
              <p className="form-group">{translations.formFill[lang]}</p>
              <div className="form-group form-check d-flex align-items-center">
                <input
                  className="form-check-input mt-0"
                  id="rememberMe"
                  type="checkbox"
                  name="rememberMe"
                  onChange={() => setCallMeChecked(!callMeChecked)}
                  value={callMeChecked}
                  checked={callMeChecked}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  {translations.getInTouch[lang]}
                </label>
              </div>
            </div>
          )}
          {callMeOptionId && callMeChecked && (
            <div className="qr-question-call-me">
              <div className="qr-question-call-me__input-block">
                <label htmlFor="userName">{translations.name[lang]}</label>
                <input
                  autoComplete="off"
                  className="form-control"
                  id="userName"
                  type="text"
                  name="userName"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="qr-question-call-me__input-block">
                <label htmlFor="tel">{translations.phone[lang]}</label>
                <PhoneInput
                  country={getUserCountry().id.toLowerCase()}
                  value={phoneNumber}
                  onChange={handleOnPhoneChange}
                  enableSearch
                  countryCodeEditable={false}
                  inputProps={{ name: 'tel' }}
                  containerClass="form-control form-control__reactPhone"
                  inputStyle={inputStyle}
                  searchPlaceholder={translations.search[lang]}
                  searchNotFound={translations.noEntries[lang]}
                />
              </div>
              <div className="qr-question-call-me__confidential-terms">
                <p className="support-help-text">
                  <IconAlert className="svg-sprite-icon icon-alert" />
                  {translations.sendClicking[lang]}
                  <Link to="/policy" target="_blank" rel="noopener noreferrer">
                    {translations.privacyTerms[lang]}
                  </Link>
                </p>
              </div>
            </div>
          )}
          <div className="qr-question-form__controls">
            <button
              className="btn qr-question-form__btn"
              type="submit"
              disabled={submitDisabled}
            >
              {nextQuestions.length === 1
                ? translations.send[lang]
                : translations.next[lang]}
            </button>
          </div>
        </form>
      </div>
      <QrFooter developedIn={developedIn} />
    </>
  );
};

export default QrQuestions;

QrQuestions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  onSubmitFunc: PropTypes.func,
  developedIn: PropTypes.string,
  lang: PropTypes.string
};

QrQuestions.defaultProps = {
  data: null,
  title: '',
  onSubmitFunc: null,
  developedIn: '',
  lang: 'en'
};
